import React, {FC, PropsWithChildren} from 'react';
import { divIcon, LatLngExpression, Point } from 'leaflet';
import { IServer } from '../../model/session-region';
import { CircleMarker, Marker, Popup } from 'react-leaflet';
import { ServersTable } from '../servers-table/servers-table';

interface PropTypes {
  center: LatLngExpression
  color: string
  radius: number
  servers: IServer[]
}

export const ServersCircleMarker : FC<PropsWithChildren<PropTypes>> = (props) => {

  const buildTextIcon = (text: string) => {return divIcon({ html: text, className: "text-center fw-bold", iconSize: new Point(50,20) });}

  const popup = <Popup maxHeight={500} maxWidth={1000}><ServersTable servers={props.servers} /></Popup>

  return props.servers.length > 0
    ? <>
        <CircleMarker
          center={props.center}
          color={props.color}
          radius={props.radius}
          fillOpacity={0.5}
          stroke={false}>
          {props.children}
        </CircleMarker>
        <Marker position={props.center} icon={buildTextIcon(props.servers.length.toLocaleString())} >
          {popup}
        </Marker>
      </>
    : null
}


