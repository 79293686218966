import React, {FC} from 'react';
import {SessionsRouteItems} from '../SessionsRouteItems';
import {Routes, Route} from "react-router-dom";
import {SessionsIndex} from './sessions';
import {joinRoute} from "@yakoffice/shared-components";

export const ProjectsIndex : FC = () => {

    return <Routes>
            <Route path={joinRoute(SessionsRouteItems.ProjectIdParam, SessionsRouteItems.GameEnvironmentIdParam, SessionsRouteItems.Sessions)} element={<SessionsIndex />}/>
          </Routes>
}

export default ProjectsIndex;

