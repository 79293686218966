import React, { FC } from 'react';
import moment from 'moment/moment';
import { IServer } from '../../model/session-region';
import { YoTable } from '@yakoffice/shared-components';

interface PropTypes {
  servers : IServer[]
}

export const ServersTable : FC<PropTypes> = ({servers}) => {

  return <YoTable responsive hover>
    <thead className="table-dark">
      <tr>
        <th>Id</th>
        <th>Hostname</th>
        <th>Start Time</th>
        <th>Status</th>
        <th>Active Sessions</th>
      </tr>
    </thead>
    <tbody>
    {servers
      .sort((a,b) => a.Hostname.localeCompare(b.Hostname))
      .map(server => (
      <tr key={server.Hostname}>
        <td>{server.Id}</td>
        <td>{server.Hostname}</td>
        <td>{moment(server.StartTime).format("DD MMM YYYY HH:mm [UTC]")}</td>
        <td>{server.StatusString}</td>
        <td>{server.ActiveSessions}</td>
      </tr>
    ))
    }
    </tbody>
  </YoTable>
}
