import Col from 'react-bootstrap/Col';
import { InfoBox, InfoBoxBody, InfoBoxHeader } from '@yakoffice/info-box';
import Row from 'react-bootstrap/Row';
import React, { FC } from 'react';
import { ISessionRegion } from '../../model/session-region';
import sass from './session-highlights.module.sass'
import {CollapsePanel} from "@yakoffice/shared-components";

interface PropTypes{
  sessionRegions: ISessionRegion[]
}

export const SessionHighlights : FC<PropTypes> = ({sessionRegions}) => {

  const totals ={
    activeSessions: sessionRegions.reduce((accumulator, current) => accumulator + current.ActiveSessions, 0),
    serversAvailable: sessionRegions.reduce((accumulator, current) => accumulator + current.ServersAvailable, 0),
    serversSuspect: sessionRegions.reduce((accumulator, current) => accumulator + current.ServersSuspect, 0),
    serversUnavailable: sessionRegions.reduce((accumulator, current) => accumulator + current.ServersUnavailable, 0)
  }

  return <CollapsePanel>
    <Row>
      <Col xs="6" md="2">
        <InfoBox>
          <InfoBoxHeader><i className={`fas fa-globe`} />Regions</InfoBoxHeader>
          <InfoBoxBody className="text-center"><h4>{sessionRegions.length}</h4></InfoBoxBody>
        </InfoBox>
      </Col>
      <Col xs="6" md="3">
      <InfoBox>
          <InfoBoxHeader><i className={`fas fa-users`} />Active Sessions</InfoBoxHeader>
          <InfoBoxBody className="text-center"><h4>{totals.activeSessions.toLocaleString()}</h4></InfoBoxBody>
        </InfoBox>
      </Col>
      <Col xs="6" md="2">
      <InfoBox>
          <InfoBoxHeader className={totals.serversAvailable > 0 ? sass.success : sass.danger}><i className="fas fa-circle"/>Servers Available</InfoBoxHeader>
          <InfoBoxBody className="text-center"><h4>{totals.serversAvailable.toLocaleString()}</h4></InfoBoxBody>
        </InfoBox>
      </Col>
      <Col xs="6" md="2">
      <InfoBox>
          <InfoBoxHeader className={totals.serversSuspect === 0 ? sass.success : sass.warning}><i className="fas fa-circle" />Servers Suspect</InfoBoxHeader>
          <InfoBoxBody className="text-center"><h4>{totals.serversSuspect.toLocaleString()}</h4></InfoBoxBody>
        </InfoBox>
      </Col>
      <Col xs="12" md="3">
        <InfoBox>
          <InfoBoxHeader className={totals.serversUnavailable === 0 ? sass.success : sass.danger}><i className="fas fa-circle" />Servers Unavailable</InfoBoxHeader>
          <InfoBoxBody className="text-center"><h4>{totals.serversUnavailable.toLocaleString()}</h4></InfoBoxBody>
        </InfoBox>
      </Col>
    </Row>
  </CollapsePanel>
}
