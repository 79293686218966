import { useParams } from 'react-router-dom';
import { useConfig } from './use-config';

export const useProjectConfig = () => {

  const {projectId} = useParams<{projectId: string}>()
  const config = useConfig()

  return config.projectConfig.find(p => p.projectId.toLowerCase() === projectId);
}
