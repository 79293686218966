import React, { FC } from 'react';
import { Form } from 'react-bootstrap';


interface PropTypes{
  selectedRegionId: string | null
  regionIds : string[]
  handleOnChange : (regionId: string | null) => void
}

export const RegionSelect : FC<PropTypes> = ({selectedRegionId, regionIds, handleOnChange}) => {

  return <Form.Select value={selectedRegionId != null ? selectedRegionId : ""}
                       onChange={e => handleOnChange(e.target.value !== "" ? e.target.value : null)}>
    <option value="">Select a value...</option>
    {regionIds.map(regionId =><option key={regionId} value={regionId}>{regionId}</option>)}
  </Form.Select>
}
