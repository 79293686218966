

export enum ServerStatus {
   Unknown    = 0,
  Available  = 1,
  Suspect    = 2,
  Offline    = 3,
  Draining   = 4,
  Terminated = 5,
}
