import React, { FC, useEffect, useState } from 'react';
import styles     from './collapse-panel.module.sass'
import Accordion  from 'react-bootstrap/cjs/Accordion';
import Button     from 'react-bootstrap/cjs/Button';

export const CollapsePanel : FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({className, ...props}) => {

  const [isMobile]                = useState(document.documentElement.clientWidth < 768)
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (isMobile)
      setCollapsed(true);
  }, [isMobile]);

  return (
    <Accordion activeKey={collapsed ? "" : "0"} className={styles.collapsePanel}>
      <Accordion.Collapse eventKey="0" className={styles.collapsePanelContent}>
        <div>{props.children}</div>
      </Accordion.Collapse>
      <Accordion.Button as={Button} variant="link" onClick={() => setCollapsed(!collapsed)} className={styles.collapsePanelToggle} />
    </Accordion>
  )
}
