import { CircleMarker, Tooltip, useMap } from 'react-leaflet';
import React, { FC, useState } from 'react';
import { ISessionRegion } from '../../model/session-region';
import { LatLong, RegionIdLatLongMap } from './regionid-lat-long-map';
import { ServerStatus } from '../../model/server-status';
import { ServersCircleMarker } from '../servers-circle-marker/servers-circle-marker';
import { MapEventHandler } from '../map-event-handler/map-event-handler';

interface PropTypes{
  regionId: string,
  sessionRegion: ISessionRegion
}

export const SessionRegionMarkers : FC<PropTypes> = ({regionId, sessionRegion}) => {

  const map = useMap()
  const [zoom, setZoom] = useState<number>(map.getZoom())

  const filteredServers = {
    available: sessionRegion.Servers.filter(s => s.Status === ServerStatus.Available),
    suspect:  sessionRegion.Servers.filter(s => s.Status === ServerStatus.Suspect),
    unAvailable: sessionRegion.Servers.filter(s => [ServerStatus.Unknown, ServerStatus.Offline, ServerStatus.Draining, ServerStatus.Terminated].includes(s.Status))
  }

  const latLong = RegionIdLatLongMap[regionId] || new LatLong(-35,80);

  const calcActiveSessionRadius = 15 * (sessionRegion.ActiveSessions > 0 ? Math.log(sessionRegion.ActiveSessions / 10) : 0);
  const calcServerMarkerLatOffset = 25/((2*zoom)*0.5*Math.log(Math.max(Math.abs(latLong.Lat),1)));
  const calcServerMarkerLongOffset = 25/(2 * zoom);

  return <React.Fragment>
    <MapEventHandler handlers={{zoomend: event => setZoom(event.sourceTarget._zoom)}} />

    <CircleMarker
      key={`${regionId}-activeSessions`}
      center={[latLong.Lat, latLong.Long]}
      radius={calcActiveSessionRadius}
      fillOpacity={0.35}
      stroke={false}>
    </CircleMarker>

    <ServersCircleMarker
      key={`${regionId}-serversAvailable`}
      center={[latLong.Lat+calcServerMarkerLatOffset, latLong.Long]}
      color={"green"}
      radius={15}
      servers={filteredServers.available}>
      <Tooltip direction="right" offset={[10,-10]} opacity={1} permanent={true}>
        <span>{`${regionId}: ${sessionRegion.ActiveSessions.toLocaleString()}`}</span>
      </Tooltip>
    </ServersCircleMarker>

    <ServersCircleMarker
      key={`${regionId}-serversSuspect`}
      center={[latLong.Lat-calcServerMarkerLatOffset, latLong.Long-calcServerMarkerLongOffset]}
      color={"orange"}
      radius={15}
      servers={filteredServers.suspect}
    />

    <ServersCircleMarker
      key={`${regionId}-serversUnavailable`}
      center={[latLong.Lat-calcServerMarkerLatOffset, latLong.Long+calcServerMarkerLongOffset]}
      color={"red"}
      radius={15}
      servers={filteredServers.unAvailable}
    />

  </React.Fragment>
}
