
export interface ISessionsConfigs{
    dev : IEnvironmentConfig
    prd : IEnvironmentConfig
}

export interface IEnvironmentConfig{
    projectConfig : IProjectConfig[]
}

export interface IProjectConfig{
    projectId: string,
    geSessionConfigs: IGameEnvironmentConfig[]
}

export interface IGameEnvironmentConfig{
    gameEnvironmentId: string,
    realtimeDatabasePath: string
}

export const SessionsConfig : ISessionsConfigs = {
  dev: {
    projectConfig: [
      {
        projectId: "ppf",
        geSessionConfigs: [

          {
            gameEnvironmentId: "Development",
            realtimeDatabasePath: "ppf/sessionRegions/"
          },
          {
            gameEnvironmentId: "Production",
            realtimeDatabasePath: "ppf/sessionRegions/"
          }
        ]
      },
      //Note:  Next Project Here
    ]
  },
  prd: {
    projectConfig: [
      {
        projectId: "ppf",
        geSessionConfigs: [
          {
            gameEnvironmentId: "Production",
            realtimeDatabasePath: "ppf/sessionRegions/"
          },
        ]
      },
      //Note:  Next Project Here
    ]
  }
};
