import React, {FC} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {SessionsRouteItems} from "../../routes/SessionsRouteItems";
import DropdownButton from "react-bootstrap/esm/DropdownButton";
import Dropdown from "react-bootstrap/esm/Dropdown";
import { useGameEnvironmentCssClass } from '@yakoffice/use-game-environment-css-class';
import { useProjectConfig } from '../use-config/use-project-config';

interface PropTypes{
    handleGameEnvironmentChange?: (gameEnvironmentId: string) => void
}

export const GameEnvironmentSelector : FC<PropTypes> = ({handleGameEnvironmentChange}) => {

    const {projectId} = useParams<{projectId: string}>()
    const {gameEnvironmentId} = useParams<{gameEnvironmentId: string}>()
    const navigate = useNavigate()
    const projectConfig = useProjectConfig()
    const cssClass = useGameEnvironmentCssClass();

    const changeGameEnvironment = (newGameEnvironmentId: string) => {
        if(handleGameEnvironmentChange)
            handleGameEnvironmentChange(newGameEnvironmentId);

        navigate(`../${projectId}/${newGameEnvironmentId.toLowerCase()}/${SessionsRouteItems.Sessions}`)
    }

    return (
            <DropdownButton align="end" title={<span className="text-capitalize">{gameEnvironmentId}</span>} variant={cssClass}>
                {projectConfig?.geSessionConfigs
                    .map(geConfig =>
                     <Dropdown.Item key={geConfig.gameEnvironmentId} as="button" onClick={() => changeGameEnvironment(geConfig.gameEnvironmentId)}>
                         {geConfig.gameEnvironmentId}
                     </Dropdown.Item>
                )}
            </DropdownButton>
    );
}
