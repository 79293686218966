import { useParams } from 'react-router-dom';
import { useProjectConfig } from './use-project-config';

export const useGameEnvironmentConfig = () => {

    const projectConfig = useProjectConfig()
    const {gameEnvironmentId} = useParams<{gameEnvironmentId: string}>()

    return projectConfig?.geSessionConfigs.find(ge => ge.gameEnvironmentId.toLowerCase() === gameEnvironmentId.toLowerCase());
}
