import * as React from 'react';
import {FC} from 'react';
import {Route, Routes} from 'react-router-dom';
import {SessionsRouteItems} from "../../routes/SessionsRouteItems";
import { NotificationErrorBoundary } from '@yakoffice/notification-handler';
import { ProjectsIndex } from '../../routes/projects';
import { YakofficeHeader } from '@yakoffice/yakoffice-header';
import {matchDeeply} from "@yakoffice/shared-components";

export const App : FC = () => {

  return <div id="app">
    <NotificationErrorBoundary errorTitle="Error Loading Yakoffice Header">
      <YakofficeHeader hostedInPortal={false}>
        <Routes>
          <Route path={matchDeeply(SessionsRouteItems.Projects)} element={<ProjectsIndex/>}/>
        </Routes>
      </YakofficeHeader>
    </NotificationErrorBoundary>
  </div>
}

