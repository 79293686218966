import { FC, useState } from 'react';
import React from 'react';
import { useSessionRegionsSnapShots } from '../use-session-regions-snap-shots/use-session-regions-snap-shots';
import { CustomSpinner } from '@yakoffice/custom-spinner';
import Container from 'react-bootstrap/Container';
import { ViewHeader } from '@yakoffice/view-header';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { GameEnvironmentSelector } from '../game-environment-selector';
import { SessionHighlights } from '../session-highlights/session-highlights';
import { SessionRegionsMap } from '../session-regions-map/session-regions-map';
import { ServersTable } from '../servers-table/servers-table';
import { RegionSelect } from '../region-select/region-select';

export const SessionRegions : FC = () => {

  const sessionRegions = useSessionRegionsSnapShots()
  const [regionId, setRegionId] = useState<string | null>(null)

  return (
    <div id="sessions" className="pt-4">
      <Container>
        <ViewHeader
          title={"Sessions"}
          customAction={<GameEnvironmentSelector handleGameEnvironmentChange={() => setRegionId(null)} />}
        />
        {Object.keys(sessionRegions).length === 0
          ? <CustomSpinner spinnerText={"Loading session information..."} />
          : <>
           <div className="mb-4"><SessionHighlights sessionRegions={Object.keys(sessionRegions).map(key => sessionRegions[key])} /></div>
            <Row>
              <Col>
                <SessionRegionsMap sessionRegions={sessionRegions} />
              </Col>
            </Row>

            <Row className="row-divider mt-3 pb-3">
              <Col md={8}><h3>{regionId != null ? `${regionId} Servers` : "Select region:"} </h3></Col>
              <Col>
                <RegionSelect selectedRegionId={regionId}
                              regionIds={Object.keys(sessionRegions)}
                              handleOnChange={selectedRegionId => setRegionId(selectedRegionId)} />
              </Col>
            </Row>
            {regionId &&
              <Row>
                <Col>
                  <ServersTable servers={sessionRegions[regionId || Object.keys(sessionRegions)[0]].Servers} />
                </Col>
              </Row>
            }
          </>
        }
      </Container>
    </div>
  )
}
