import React, { FC } from 'react';
import {useAuthUser} from '@yakoffice/yakoffice-firebase';
import { SessionRegions } from '../../../components/session-regions';
import { useParams } from 'react-router-dom';
import {NoAccess} from "@yakoffice/shared-components";

export const SessionsIndex : FC = () => {

    const {projectId} = useParams() as {projectId: string};
    const authUser = useAuthUser();

    return authUser?.isAuthorisedForHostedToolProject(projectId)
      ? <SessionRegions />
      : <NoAccess />
}
