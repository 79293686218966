
export class LatLong {
  readonly Lat: number
  readonly Long: number

  constructor(lat: number, long: number) {
    this.Lat = lat;
    this.Long = long;
  }
}

export const RegionIdLatLongMap : {[key: string]: LatLong} = {
  asianortheast1: new LatLong(30,139),//tokyo
  asianortheast3: new LatLong(42,126),//seoul
  asiasoutheast: new LatLong(8,115),
  asiasouth: new LatLong(19,72),// mumbai
  australiasoutheast: new LatLong(-28,150),
  europenorth: new LatLong(63, 25),//finland
  europewest: new LatLong(48, 0),
  southamericaeast: new LatLong(-23, -46),
  uswest: new LatLong(44, -125),
  uscentral: new LatLong(27, -98),
  useast: new LatLong(43, -75)
}
