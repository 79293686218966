import {useEffect, useState}        from "react";
import { ISessionRegions }          from '../../model/session-region';
import { useGameEnvironmentConfig } from '../use-config/use-gameenvironment-config';
import { getDatabase, ref, onChildAdded, onChildChanged, onChildRemoved, off } from "firebase/database";


export const useSessionRegionsSnapShots = () => {

  const config = useGameEnvironmentConfig()
  const [sessionRegions, setSessionRegions] = useState<ISessionRegions>({});

  useEffect(() => {
    if (config) {
        console.debug(`Registering for session RTDB snapshots for GameEnvironment: ${config.gameEnvironmentId}`)

        setSessionRegions({});

        const database  = getDatabase();
        const dbRef     = ref(database, config.realtimeDatabasePath)

      onChildAdded(dbRef, (snapshot) => {
          const key = snapshot.key;
          if (key != null)
            setSessionRegions(existing => {
              const newItem = { ...existing };
              newItem[key] = snapshot.val();
              return newItem
            });
        });

        onChildChanged(dbRef, (snapshot) => {
          const key = snapshot.key;
          if (key != null)
            setSessionRegions(existing => {
              const newItem = { ...existing };
              newItem[key] = snapshot.val();
              return newItem
            });
        })

        onChildRemoved(dbRef, (snapshot) => {
          const key = snapshot.key;
          if (key != null)
            setSessionRegions(existing => {
              const newItem = { ...existing }
              delete newItem[key]
              return newItem
            });
        })

        return () => off(dbRef);

    }}, [config]);

  return sessionRegions;
};
