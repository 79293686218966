import { FC } from 'react';
import { useMapEvents } from 'react-leaflet';
import { LeafletEventHandlerFnMap } from 'leaflet';

interface PropTypes {
  handlers: LeafletEventHandlerFnMap
}

export const MapEventHandler : FC<PropTypes> = ({handlers}) => {
  useMapEvents(handlers)
  return null;
}
