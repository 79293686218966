import { MapContainer, TileLayer } from 'react-leaflet';
import { SessionRegionMarkers } from '../session-region-markers/session-region-markers';
import React, { FC } from 'react';
import { ISessionRegions } from '../../model/session-region';
import 'leaflet/dist/leaflet.css';

interface PropTypes {
  sessionRegions : ISessionRegions
}

export const SessionRegionsMap : FC<PropTypes> =  ({sessionRegions}) => {
  return (
    <MapContainer
      style={{ height: '50vh', zIndex: 0 }}
      center={[35, 0]}
      // NB.  Bounds not working. Might be because we are not on latest version of react
      // bounds={[[-20.505, -20.09],[20.505, 20.09]]}
      // boundsOptions={{padding: [50, 50]}}
      zoom={2}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {Object
        .keys(sessionRegions)
        .sort()
        .map(key => <SessionRegionMarkers key={key} regionId={key} sessionRegion={sessionRegions[key]} />)}
    </MapContainer>
  )
}
