import { useParams } from "react-router-dom";
import {useEffect, useState} from "react";

export const useGameEnvironmentCssClass = (desiredDameEnvironmentId?: string) => {

    const {gameEnvironmentId} = useParams<{gameEnvironmentId:string}>()
    const [className, setClassName] = useState("success")

    useEffect(() => {
        switch ((desiredDameEnvironmentId || gameEnvironmentId).toLowerCase()) {
            case "development":
                setClassName("success");
                break;
            case "staging":
                setClassName("warning");
                break;
            case "production":
                setClassName("danger");
                break;
            default:
                setClassName("info");
                break;
        }
    },[gameEnvironmentId, desiredDameEnvironmentId])

    return className;
};
