import type {IEnvironmentConfig} from "./sessions-config";
import {SessionsConfig} from "./sessions-config";

export const getSessionsConfig = () : IEnvironmentConfig => {

    console.log("process.env.REACT_APP_FIREBASE_CONFIG: ", process.env.REACT_APP_FIREBASE_CONFIG)

    return process.env.REACT_APP_FIREBASE_CONFIG === "production"
        ? SessionsConfig.prd
        : SessionsConfig.dev;
}
